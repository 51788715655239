import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

export const ChartStatus = ({ data, ...rest }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const optionsTotal = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    labels: [
      t('queueMonitor.table.th-dialing'),
      t('queueMonitor.table.th-talking'),
    ],
    colors: ['#000000', '#4c48ff', '#a03ffc'],
    legend: {
      position: 'bottom',
      show: false,
      fontSize: '10px',
      markers: {
        width: 8,
        height: 8,
        strokeWidth: 0,
      },
    },
    dataLabels: {
      enabled: true,

      formatter: function (val, opts) {
        const dataPointIndex = opts.dataPointIndex;
        const renderValueTalking = val >= 10 ? val / 10 : val;
        return dataPointIndex === 1 ? renderValueTalking : val;
      },

      dropShadow: {
        enabled: false,
      },
      style: {
        fontSize: '12px',
      },
    },
    stroke: {
      show: false,
      width: 0,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        borderRadius: 3,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: theme.colors.typography.baseText,
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  };
  return <Chart options={optionsTotal} series={data} type='bar' {...rest} />;
};
