import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerLoading = styled(motion.div)`
  position: absolute;
  user-select: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.containers.baseContainer};
  cursor: not-allowed;
  z-index: 10000;
  img {
    width: 100%;
    max-width: 300px;
    height: 79px;
    user-select: none;
  }
`;

export const ContainerLoadingInWindow = styled(motion.div)`
  user-select: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: not-allowed;
  z-index: 10000;
  img {
    width: 100%;
    max-width: 320px;
    user-select: none;
  }
`;

export const ContainerLoadingDisabledScreen = styled(motion.div)`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  cursor: not-allowed;
  background-color: transparent;
`;

export const ContainerBackdropLoading = styled.div`
  position: absolute;
  z-index: 999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.colors.containers.baseContainer}80;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${(props) => props.theme.colors.typography.baseTitle};
  }
`;
