import { useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import {
  dataInicialIdComponent,
  idSubItem,
  isCloseItem,
  resetCurrentPageId,
} from '@store/NavigationDashboard';

import { InfoUserComponent } from '@components/pages/Dashboard/InfoUserComponent';
import { ImageComponent } from '@components/layout/ImageComponent';

import LogoSidebarImg from '@/assets/imgs/sm_voiccelabs_black.webp';
import LogoWhiteSidebarImg from '@/assets/imgs/sm_voiccelabs_white.webp';

import {
  SideBarContainer,
  HeaderSidebar,
  DividerX,
  FullContainerSidebar,
  Copyright,
  ContainerItemsNavigation,
} from './styled';
import { schemaScreens } from '@/store/AuthenticatorStore';
import { SectionItemNavigation } from '@/components/pages/Dashboard/SectionItemNavigation';
import { Stack } from '@mui/system';
import { useSecurityProfileRender } from '@/hooks/Navigator/useSecurityProfileRender';

export const Sidebar = ({ stateSidebar }) => {
  const { t } = useTranslation();
  const { isSysAdm } = useSecurityProfileRender();
  const theme = useTheme();
  const [screenSchema, setScreenSchema] = useAtom(schemaScreens);
  const [inicialIdComponent] = useAtom(dataInicialIdComponent);
  const [, resetCurrentId] = useAtom(resetCurrentPageId);
  const [, resetSubId] = useAtom(idSubItem);
  const hasAdmArea =
    !!screenSchema &&
    screenSchema.filter((item) => item.area === 'Administrator');

  const hasDisplayPermission = (hasAdmArea) => {
    if (hasAdmArea) {
      for (const item of hasAdmArea) {
        for (const permission of item.permissions) {
          if (permission.name === 'display' && permission.value === true) {
            return true;
          }
        }
      }
      return false;
    }
    return false;
  };

  const hasItemShow = hasDisplayPermission(!hasAdmArea ? [] : hasAdmArea);

  const sidebarRef = useRef(null);
  const [isClose] = useAtom(isCloseItem);
  const [typeMenu, setTypeMenu] = useState('');

  const logoToPreload =
    theme.title === 'Light' ? LogoSidebarImg : LogoWhiteSidebarImg;

  const handleVerifyOpensMenus = (typeMenu) => {
    setTypeMenu(typeMenu);
  };

  const sidebarIsOpem = isClose ? 0 : 1;
  const stateItemSidebar = 0;

  const handleHome = () => {
    resetSubId(false);
    resetCurrentId(inicialIdComponent);
    setScreenSchema(
      screenSchema.map((item) => {
        return {
          ...item,
          isOpenMenu: false,
        };
      })
    );
  };

  let dateFullYear = new Date().getFullYear();

  return (
    <FullContainerSidebar
      animate={{ width: `${stateSidebar}px` }}
      transition={{ duration: 0 }}
    >
      <SideBarContainer
        ref={sidebarRef}
        animate={{ width: `${stateSidebar}px` }}
        transition={{ duration: 0 }}
      >
        <HeaderSidebar
          animate={{
            x: `${stateItemSidebar}px`,
            opacity: sidebarIsOpem,
          }}
          transition={{ duration: 0.1 }}
          onClick={handleHome}
          whileTap={{
            scale: 0.99,
          }}
        >
          <ImageComponent
            src={logoToPreload}
            width='200px'
            height='55px'
            alt='Logo Voicce Labs'
            loading='eager'
          />
        </HeaderSidebar>
        <InfoUserComponent
          stateItem={stateItemSidebar}
          sidebarIsOpem={sidebarIsOpem}
        />
        <ContainerItemsNavigation>
          <Stack direction={'row'} alignItems={'center'}>
            <h2>{t('dashboard.label-products')}</h2>
            <DividerX />
          </Stack>

          <SectionItemNavigation
            screenSchema={screenSchema}
            setScreenSchema={setScreenSchema}
            stateItemSidebar={stateItemSidebar}
            sidebarIsOpem={sidebarIsOpem}
            handleVerifyOpensMenus={handleVerifyOpensMenus}
            typeMenu={typeMenu}
            sectionMenuArea={'products'}
          />

          {isSysAdm && hasAdmArea && hasAdmArea.length > 0 && hasItemShow && (
            <Stack direction={'row'} alignItems={'center'}>
              <h2>{t('dashboard.label-adm')}</h2>
              <DividerX />
            </Stack>
          )}
          {isSysAdm && (
            <SectionItemNavigation
              screenSchema={screenSchema}
              setScreenSchema={setScreenSchema}
              stateItemSidebar={stateItemSidebar}
              sidebarIsOpem={sidebarIsOpem}
              handleVerifyOpensMenus={handleVerifyOpensMenus}
              typeMenu={typeMenu}
              sectionMenuArea={'administrator'}
            />
          )}
        </ContainerItemsNavigation>
      </SideBarContainer>
      {!isClose && (
        <Copyright>
          <span>
            {t('dashboard.messageCopyrightSidebar', {
              year: dateFullYear,
            })}
          </span>
          <span>{t('dashboard.versionApp')}: 1.0.0</span>
        </Copyright>
      )}
    </FullContainerSidebar>
  );
};
